import React, { useEffect } from 'react';
import jQuery from "jquery";
import classes from "./Quiz.css"
export default function Quiz ({ButtonIcon, ButtonWidth, ButtonHeight}) {
    let cssButtonWidth = ButtonWidth ? ButtonWidth : '80px';
    let cssButtonHeight = ButtonHeight ? ButtonHeight : '80px';

    useEffect(() => {
        ( function( $ ) {
            $(function() {
                var show = false;
                $('.survey-button').on('click',function(){
                    if(!show){
                        $('.encuesta-container').css({display:'block'}).animate({
                            opacity:1,
                            height:'600px'
                        });
                        show = true;
                    }else{
                        $('.encuesta-container').css({display:'none'}).animate({
                            opacity:0,
                            height:'0px'
                        });
                        show = false;
                    }


                });

                setTimeout(() => {
                    $('.survey-button').css({display:'block'});
                },5000)
            });
        } )( jQuery );
    });

    return (
        <>
            <div className={["encuesta-container", classes.encuesta_container].join(" ")}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSe1RwRGIZUn7p2fGI7mgk94ZwEVOQ5Tju_4qfN42tXXMHKKfA/viewform?embedded=true"
                    width="400" height="600" frameBorder="0" marginHeight="0" marginWidth="0" title="Ecnuesta">Loading…
                </iframe>
            </div>

            <div className={["survey-button", classes.survey_button].join(" ")}>
                <IconImage
                    ButtonIcon = {ButtonIcon}
                />
            </div>
            <style jsx>{`
              .survey-button{
                  width: ${cssButtonWidth};
                  height: ${cssButtonHeight};
              }
              .encuesta-container{
                  display: none;
                  opacity: 0;
                  position: fixed;
                  background-color: #012437;
                  border: 1px solid #000000;
                  -moz-border-radius: 7px;
                  -webkit-border-radius: 7px;
              }


            `}</style>
        </>
    );
}

const IconImage = ({ButtonIcon}) => {
    if(ButtonIcon){
        return (
            <img width="100%" alt="Encuesta" src={ButtonIcon} /> 
            );
    }else{
        return(
            <img width="100%" alt="Encuesta" src="survey-2316468_1280.png" />
        );
    }
}