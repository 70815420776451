import React, { useState, useEffect, useRef } from "react";
import classes from "./Chart.css";
import { widget } from "../../charting_library";
import Datafeed from "./datafeed.js";
import { getLan } from "../../stores/utils/utilities.js";
import SymbolInfoWidget from "./SymbolInfoWidget";
import PropTypes from 'prop-types';

const timeFrameRelation = new Map(Object.entries({
  '2H': "D", '1D': "D", '10D': "10D", '1M': "1M", '3M': "3M", '6M': "6M", '1Y': "12M", '4Y': "48M", '5Y': "60M"
}));

const intervalRelation = new Map(Object.entries({
  '2H': "30S", "1D": "1", '10D': "1D", '1M': "1D", '3M': "1D", '6M': "1D", '1Y': "1D", '4Y': "1D", '5Y': "1D"
}));

const periodos = ["2H", "1D", "10D", "1M", "3M", "6M", "1Y", "4Y", "5Y"];

export default function Chart(props) {
  let [periodo, setPeriodo] = useState("1D");
  let [quantity, setQuantity] = useState("1");
  let [timeMetric, setTimeMetric] = useState("D");

  function cambioPeriodo(periodo) {
    let periodoSplit = periodo.split(/(\d+)/);
    setPeriodo(periodo);
    setQuantity(periodoSplit[1]);
    setTimeMetric(periodoSplit[2]);
  }

  let renderedPeriodos = periodos.map((item) => (
    <button key={item}
      className={[ classes.btn, "montse", periodo === item ? classes.btnActivo : null,].join(" ")}
      onClick={(e) => cambioPeriodo(item)}
    >
      {item}
    </button>
  ));

  return (
    <>
      <div className="row">
        <div className="col-12 sin_padding_L sin_padding_R margin_top_20">
          <SymbolInfoWidget claveEmisora={props.claveEmisora} symbol={props.nombreEmision} locale={getLan()}/>
        </div>
        <div className="col-12 sin_padding_L margin_top_20 margin_bottom_20">
          {renderedPeriodos}
        </div>
      </div>
      <TVChartContainer periodo={periodo} quantity={quantity} timeMetric={timeMetric} isin={props.isin} emision={props.nombreEmision}/>
    </>
  );
}

Chart.propTypes = {
  claveEmisora: PropTypes.string,
  nombreEmision: PropTypes.string,
};

let TVChartContainer = (props) => {
  const chartContainerRef = useRef();
  useEffect(() => {
    let interval = intervalRelation.get(props.quantity + props.timeMetric);
    let timeframe = timeFrameRelation.get(props.quantity + props.timeMetric);
    let symbolElements = [props.emision, props.isin, props.quantity, props.timeMetric];
    const widgetOptions = {
      symbol: symbolElements.join("_"),
      container: chartContainerRef.current,
      datafeed: Datafeed,
      library_path: "/charting_library/",
      enabled_features: [
        //"hide_left_toolbar_by_default",
        "hide_resolution_in_legend",
      ],
      disabled_features: [
        "header_symbol_search",
        "header_compare",
        "symbol_search_hot_key",
        "edit_buttons_in_legend",
        "timeframes_toolbar",
        "header_resolutions"
      ],
      locale: getLan() || "es",
      timezone: 'exchange',
      interval: interval !== undefined ? interval : "1",
      timeframe: timeframe !== undefined ? timeframe : "D",
      autosize: true,
      overrides: {
        "mainSeriesProperties.style": 3,
      }
    };

    if (widgetOptions.symbol === undefined) {
      return;
    }
    new widget(widgetOptions);
  });

  return <>
    <div ref={chartContainerRef} className={[classes.TVChartContainer].join(" ")} />
    <div className="nunito texto_14">Chart by <a href="https://www.tradingview.com/">TradingView</a></div><br/>
  </>;
};